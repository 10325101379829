import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/resources',
    name: 'resources',    
    component: function () {
      return import(/* webpackChunkName: "resources" */ '../views/Resources.vue');
    }
  },
  {
    path: '/coming-soon/aesthesis',
    name: 'aesthesis',    
    component: function () {
      return import(/* webpackChunkName: "aesthesis" */ '../views/ComingSoonAesthesis.vue');
    }
  },
  {
    path: '/coming-soon/zaabeel',
    name: 'zaabeel',    
    component: function () {
      return import(/* webpackChunkName: "zaabeel" */ '../views/ComingSoonZaabeel.vue');
    }
  },
  {
    path: '/coming-soon/kea-island',
    name: 'KeaIsland',    
    component: function () {
      return import(/* webpackChunkName: "KeaIsland" */ '../views/ComingSoonKeaIsland.vue');
    }
  },
  {
    path: '/coming-soon/moonlight-basin',
    name: 'moonlight-basin',    
    component: function () {
      return import(/* webpackChunkName: "moonlight-basin" */ '../views/ComingSoonMoonlightBasin.vue');
    }
  },
  {
    path: '/resort/cape-town',
    name: 'cape-town',    
    component: function () {
      return import(/* webpackChunkName: "cape-town" */ '../views/ResortCapeTown.vue');
    }
  },
  {
    path: '/resort/desaru-coast',
    name: 'desaru-coast',    
    component: function () {
      return import(/* webpackChunkName: "desaru-coast" */ '../views/ResortDesaruCoast.vue');
    }
  },
  {
    path: '/resort/gorillas-nest',
    name: 'gorillas-nest',    
    component: function () {
      return import(/* webpackChunkName: "gorillas-nest" */ '../views/ResortGorillasNest.vue');
    }
  },
  {
    path: '/resort/le-saint-geran',
    name: 'le-saint-geran',    
    component: function () {
      return import(/* webpackChunkName: "le-saint-geran" */ '../views/ResortLeSaintGeran.vue');
    }
  },
  {
    path: '/resort/mandarina',
    name: 'mandarina',    
    component: function () {
      return import(/* webpackChunkName: "mandarina" */ '../views/ResortMandarina.vue');
    }
  },
  {
    path: '/resort/nyungwe-house',
    name: 'nyungwe-house',    
    component: function () {
      return import(/* webpackChunkName: "nyungwe-house" */ '../views/ResortNyungweHouse.vue');
    }
  },
  {
    path: '/resort/palmilla',
    name: 'palmilla',    
    component: function () {
      return import(/* webpackChunkName: "palmilla" */ '../views/ResortPalmilla.vue');
    }
  },
  {
    path: '/resort/portonovi',
    name: 'portonovi',    
    component: function () {
      return import(/* webpackChunkName: "portonovi" */ '../views/ResortPortonovi.vue');
    }
  },
  {
    path: '/resort/reethi-rah',
    name: 'reethi-rah',    
    component: function () {
      return import(/* webpackChunkName: "reethi-rah" */ '../views/ResortReethi.vue');
    }
  },
  {
    path: '/resort/royal-mirage',
    name: 'royal-mirage',    
    component: function () {
      return import(/* webpackChunkName: "royal-mirage" */ '../views/ResortRoyalMirage.vue');
    }
  },
  {
    path: '/resort/the-palm',
    name: 'the-palm',    
    component: function () {
      return import(/* webpackChunkName: "the-palm" */ '../views/ResortThePalm.vue');
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
