<template>  
  <picture>
    <!-- no need for media as it doesnt "swap" to another source tag -->
    <!-- srcsets from <source> tag will be used (if supported, otherwise falls back to jpg). Add format="png" on <Image> if png needed -->
    <source :srcset="srcSets" type="image/webp"/>
    <img loading="lazy" :sizes="sizes" :src="src" :alt="alt" :width="width" :height="height" :class="{cover}"/>
  </picture>
</template> 

<script setup>
  import { ref, onMounted } from "vue";
  const props = defineProps({src: String, widths: Array, alt: {type: String, default: ""}, format: {type: String, default: "jpg"}, width: {type: Number, default: 1200}, height: {type: Number, default: 425}, cover: Boolean});
  const srcSets = ref("");
  const sizes = ref("");
  const src = ref("");

  props.widths.forEach(w => {   
    try{
      srcSets.value += `${require(`/src/assets/Images/${props.src}/${props.src}_c_scale,w_${w}.webp`)} ${w}w,`;
    }
    catch{
      console.warn(`${props.src}_c_scale,w_${w}.${props.format} should also have a .webp version for perfomance gains. Fell back to .${props.format} format`);
    }  
    sizes.value += `(max-width: ${w}px) ${w}px,`;
  }); 
  
  //repeated path due to require needing full path (.jpg etc)
  onMounted(() => src.value = require(`/src/assets/Images/${props.src}/${props.src}_c_scale,w_${Math.min(...props.widths)}.${props.format}`)); //get smallest value (whatever format is passed in, jpg default)

</script>

<style lang="scss" scoped>
  //@use "../../Styles/Utility" as u;
  picture{
    img{min-width: 0;
      &.cover{height: 100%; width: 100%; object-fit: cover;}
    }    
  }
</style>