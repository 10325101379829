<template>  
  <picture>
    <source v-for="(source, i) in sources" :key="i"
     :srcset="createPaths(source.widths)"
     :media="createBreakpoint(Math.max(...source.widths))"
     type="image/webp"
    />
    <!-- add format="png" on <Image> if png needed -->
    <img loading="lazy" :src="imgSrc" :alt="alt" :width="width" :height="height" :class="{cover}" />
  </picture>
</template> 

<script setup>
  import { onMounted, ref } from "vue";
  const props = defineProps({src: String, sources: Array, alt: {type: String, default: ""}, format: {type: String, default: "jpg"}, width: Number, height: {type: Number, default: 571}, cover: Boolean});

  const imgSrc = ref("");
  const widthsArray = ref([]);
  let srcSetString = "";
  
  const createPaths = widths => {
      widths.forEach(w => {
        try{
          srcSetString += `${require(`/src/assets/Images/${props.src}/${props.src}_c_scale,w_${w}.webp`)} ${w}w,`;
        }
        catch{
          srcSetString += `${require(`/src/assets/Images/${props.src}/${props.src}_c_scale,w_${w}.${props.format}`)} ${w}w,`;
          console.warn(`${props.src}_c_scale,w_${w}.${props.format} should also have a .webp version for perfomance gains. Fell back to .${props.format} format`);
        }        
        widthsArray.value.push(w);      
      }); 
      return srcSetString;
  }

  const createBreakpoint = w => `(max-width: ${w}px)`;

  //repeated path due to require needing full path (.jpg etc)
  onMounted(() => imgSrc.value = require(`/src/assets/Images/${props.src}/${props.src}_c_scale,w_${Math.max(...widthsArray.value)}.${props.format}`)); //get largest value (whatever format is passed in, jpg default)
</script>

<style lang="scss" scoped>
  //@use "../../Styles/Utility" as u;
  picture{
    img{min-width: 0;
      &.cover{height: 100%; width: 100%; object-fit: cover;}
    }    
  }
</style>